<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>库存管理</el-breadcrumb-item>
      <el-breadcrumb-item>采购退货订单</el-breadcrumb-item>
      <el-breadcrumb-item>新增采购退货订单</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card class="box-card">
      <el-form  :model="addPurchaseForm" :rules="addPurchaseFormRules" ref="addPurchaseFormRef"
                label-width="110px">
        <el-row>
<!--          <el-col>-->
<!--          <el-form-item label="供应商"  prop="s_id">-->
<!--            <el-select v-model="addPurchaseForm.s_id" placeholder="请选择" @change="getcheckList" clearable filterable>-->
<!--              <el-option-->
<!--                  v-for="item in supplierList"-->
<!--                  :key="item.s_id"-->
<!--                  :label="item.s_company_name"-->
<!--                  :value="item.s_id">-->
<!--              </el-option>-->
<!--            </el-select>-->
<!--            <el-button class="addsupplierselect" @click="AddSupplierSelect" type="primary">添加供应商</el-button>-->
<!--          </el-form-item>-->
<!--          </el-col>-->
          <el-col>
            <el-button @click="add" type="primary" >选择采购订单</el-button>
          </el-col>
        </el-row>
        <el-row>
          <el-col></el-col>
        </el-row>
        <el-form-item label-width="0" min-width="760px">
          <el-table :data="addPurchaseForm.goodslist" border stripe>
            <el-table-column type="index"></el-table-column>
            <el-table-column label="商品编码" prop="ga_code" min-width="70px" ></el-table-column>
            <el-table-column label="商品名称" prop="g_name" min-width="70px" ></el-table-column>
              <el-table-column label="商品规格" prop="ga_name" min-width="220px" >
                <template slot-scope="scope">
                  <el-tooltip effect="dark" :content="scope.row.ga_name" placement="top" :enterable="true">
                    <div class="ga_name" >{{scope.row.ga_name}}</div>
                  </el-tooltip>
                  <el-select  v-model="scope.row.gad_id" placeholder="请选择"
                              clearable class="p_purchase_id" v-if="scope.row.goodsattrdate.length!==0">
                    <el-option
                        v-for="item in scope.row.goodsattrdate"
                        :key="item.gad_id"
                        :label="`${item.gad_start_date}~${item.gad_end_date}`"
                        :value="item.gad_id">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
            <el-table-column label="采购数量" prop="pi_number" min-width="60px">
<!--              <template slot-scope="scope">-->
<!--                <el-input v-model.number="scope.row.pi_number" placeholder="采购数量(数字)" @blur="getTotal" @keydown="handleInput2"></el-input>-->
<!--              </template>-->
            </el-table-column>


            <el-table-column label="单位" prop="gu_name" min-width="50px" ></el-table-column>
            <el-table-column label="已入库数量" prop="pi_warhouse_number" min-width="50px" ></el-table-column>
            <el-table-column label="已退货数量" prop="pri_warhouse_number" mzin-width="50px" ></el-table-column>
            <el-table-column label="采购单价" prop="pi_price" min-width="110px">
              <template slot-scope="scope">
                <el-input v-model="scope.row.pri_price" placeholder="采购单价(数字)" @keyup.native="scope.row.pri_price = money(scope.row.pri_price)"
                          @blur="getTotal" @keydown="handleInput2"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="退货数量" min-width="100px" >
              <template slot-scope="scope">
                <el-input v-model.number="scope.row.pri_number" placeholder="退货数量(数字)" @blur="getTotal(scope.row)" ></el-input>
              </template>
            </el-table-column>
            <el-table-column label="小计" prop="pi_total_price">
              <template slot-scope="scope">
<!--                {{scope.row.pri_total_price=scope.row.pri_number*scope.row.pi_price*1||0}}-->
                {{ scope.row.pi_total_price=NumberMul(scope.row.pi_number,scope.row.pi_price) || 0}}

              </template>
            </el-table-column>
<!--            <el-table-column label="删除" >-->
<!--              <template slot-scope="scope">-->
<!--                <el-button type="danger" @click="delGoodsList(scope.row.ga_id)">删除</el-button>-->
<!--              </template>-->
<!--            </el-table-column>-->

          </el-table>
        </el-form-item>
<!--        <el-form-item label="采购金额：" prop="p_price">-->
<!--          <el-input class="p_price" v-model="addPurchaseForm.p_price"></el-input>-->
<!--        </el-form-item>-->
        <el-form-item label="退货员：" prop="pr_prid">
          <el-select v-model="addPurchaseForm.pr_prid" placeholder="请选择"
                     clearable class="p_purchase_id">
            <el-option
                v-for="item in userlist"
                :key="item.m_id"
                :label="item.m_name"
                :value="item.m_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="退货日期：" prop="pr_date">
          <el-date-picker v-model="addPurchaseForm.pr_date" class="p_date"
                          type="date" value-format="yyyy-MM-dd" placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="退货其他金额：" prop="p_other_price">
          <el-input v-model="addPurchaseForm.pr_other_price" class="p_other_price"
                    @keyup.native="addPurchaseForm.pr_other_price = money(addPurchaseForm.pr_other_price)"></el-input>
        </el-form-item>
        <el-form-item label="退货备注：" prop="p_remark">
          <el-input v-model="addPurchaseForm.pr_remark" class="p_remark"></el-input>
        </el-form-item>
        <el-form-item label="退货金额：" prop="">
          <el-input v-model="addPurchaseForm.pr_total_price" class="p_other_price" disabled></el-input>
        </el-form-item>
<!--        提交-->
        <div style="float: right; padding-bottom:20px; ">
          <el-button @click="addgo">取消</el-button>

          <el-button type="primary" @click="addupPurchase" :loading="addloading">确定</el-button>
        </div>
      </el-form>
    </el-card>
    <el-dialog title="选择采购订单" :visible.sync="addPurchaseDialogVisible"
               width="70%" >
      <el-button @click="setCurrent">取 消 选 中</el-button>
      <el-table :data="purchaseList" ref="singleTable" stripe highlight-current-row   @current-change="handlePCurrentChange">

        <!--        <el-table-column type="selection" width="55"></el-table-column>-->
        <el-table-column type="index" width="50px"></el-table-column>
        <el-table-column label="采购单号" prop="p_code"></el-table-column>
        <el-table-column label="采购员" prop="p_purchase_name" width="80px"></el-table-column>
        <el-table-column label="供应商" prop="s_name" ></el-table-column>
        <el-table-column label="采购时间" prop="p_date" ></el-table-column>
        <el-table-column label="采购金额" prop="p_price" ></el-table-column>
        <el-table-column label="采购总计" prop="p_total_price" ></el-table-column>
        <el-table-column label="状态" prop="p_status_name" width="80px" ></el-table-column>
        <el-table-column label="付款状态" prop="p_payment_status_name" width="80px"></el-table-column>

      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="querInfo.page"
          :page-sizes="[3,5,10,15]"
          :page-size="querInfo.limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addPurchaseDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addPwtergo()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      addloading:false,
      querInfo: {
        page: 1,
        limit: 10,
      },
      total:0,
      addPurchaseDialogVisible:false,
      //采购列表
      purchaseList:[],
      purchaseid:'',
      // //控制添加供应商对话框的展示与隐藏
      // addSupplierDialogVisible:false,
      // //添加供应商的form表单数据
      // addsupplierForm:{},
      // // 省市区信息
      // listarea: [],
      // //三级联动的配置
      // cascaderProps: {
      //   expandTrigger: 'hover',
      //   // checkStrictly:true,
      //   value: 'la_id',
      //   label: 'la_name',
      //   children: 'children'
      // },
      //添加form表单的验证

      //添加表单的form表单
      addPurchaseForm:{
        goodslist:[

        ],
        pr_total_price:0,
        pr_date:this.$store.state.daydate,
        pr_prid:this.$store.state.m_id

      },
      // Goodslist:[],
      //员工列表
      userlist:[],
      // //供应商列表
      // supplierList:[],
      // //选中供应商的数组
      // ss_id: '',
      // id:{'s_id':''},
      //控制添加商品的显示或隐藏
      // addGoodsDialogVisible:false,
      checkBoxData: [],
      //
      // adddisabled:true,
      addPurchaseFormRules: {
        // s_id: [
        //   {required: true, message: '请选择供应商', trigger: 'change'},
        // ],
        pr_prid: [
          {required: true, message: '请选择采购员', trigger: 'change'},
        ],
        pr_date: [
          {required: true, message: '请选择采购时间', trigger: 'change'},
        ],
        // p_other_price: [
        //   {required: true, message: '请输入金额', trigger: 'change'},
        // ],
        // p_remark: [
        //   {required: true, message: '请输入备注', trigger: 'change'},
        // ]
      },
      // //商品分类list
      // categorieslList:[],
      // //选中的父级分类的ID数组
      // selectefKeys: [],
      // //指定级联选择器的配置对象
      // cascaderPropscate: {
      //   expandTrigger: 'hover',
      //   // checkStrictly:true,
      //   value: 'c_id',
      //   label: 'c_name',
      //   children: 'children'
      // },
    }
  },
  created() {
    // this.getsupplierList()
    this.getuserList()
    // this.getcateList()
    this.getpurchaseList()

  },
  methods: {

    // //获取供应商列表
    // async getsupplierList() {
    //   const {data: res} = await this.$http.get('supplier/getMainList')
    //   if (res.code !== 200) {
    //     return this.$message.error(res.msg)
    //   }
    //   // 把数据列表 赋值
    //   this.supplierList = res.data
    // },
    // //获取省市区
    // async getWarehouseArea() {
    //   const {data: res} = await this.$http.get('listarea')
    //   if (res.code != 200) return this.$message.error('获取省市区数据失败')
    //   this.listarea = res.data
    //   console.log(this.listarea)
    // },
    // //获取商品数据
    // async getGoodsList(id){
    //   const {data:res} = await this.$http.get('goods/getList',
    //       {params:id})
    //   if (res.code !== 200) {
    //     return this.$message.error(res.msg)
    //   }
    //   this.Goodslist=res.data.data
    //   this.total=res.data.total
    //   setTimeout(() => {
    //     this.pickUp() //需要默认勾选的数据
    //   }, 10)
    // },
    //获取采购订单
    async getpurchaseList() {
      const {data: res} = await this.$http.get('purchase/getList',
          {params:this.querInfo})
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      // 把数据列表 赋值
      this.purchaseList = res.data.data
      this.total=res.data.total

    },
    //获取采购员
    async getuserList() {
      const {data: res} = await this.$http.get('member/getMainList')
      if (res.code !== 200) {
        return this.$message.error(res.msg)
      }
      this.userlist=res.data
    },
    //获取采购订单详情
    async getPDli(id){
      if (id){
        const {data: res} = await this.$http.get('purchase/find/'+id)
        if (res.code !== 200) {
          return this.$message.error(res.msg)
        }
        res.data.goodslist.forEach((item)=>{
          item.pri_price=item.pi_price
        })
        this.addPurchaseForm.goodslist=res.data.goodslist
      }else{
        return false
      }

    },
    //监听pagesize改变
    handleSizeChange(newSize) {
      this.querInfo.limit = newSize
      this.getGoodsList(this.querInfo)
    },
    //监听pagenum 改变
    handleCurrentChange(newPage) {
      this.querInfo.page = newPage
      this.getGoodsList(this.querInfo)
    },
    //单选采购订单
    handlePCurrentChange(val) {
      // this.goods=val.
      this.addPurchaseForm.p_id=val.p_id
      this.addPurchaseForm.p_code=val.p_code

    },
    setCurrent(row){
      this.is_p_num=false
      this.is_p_wnum=false
      this.addWarehouseenter.goodslist=[]
      this.$refs.singleTable.setCurrentRow(row);
    },
    // 需要默认勾选的数据
    pickUp() {
      let arr = []
      this.Goodslist.forEach((item) => {
        this.addPurchaseForm.goodslist.forEach((val) => {
          if (val.ga_id === item.ga_id) {
            arr.push(item)
            console.log(arr,'123456')
            // this.radios.push(item.roleID)
          }
        })
      })
      this.toggleSelection(arr)
    },
    // checkboxT(row, rowIndex){
    //   this.Goodslist.forEach((item) => {
    //     this.addPurchaseForm.goodslist.forEach((val) => {
    //       if(val.ga_id === item.ga_id){
    //         return false;//禁用
    //       }else{
    //         return true;//不禁用
    //       }
    //     })
    //   })
    //
    //
    // },
    // 默认选择中的行
    toggleSelection(rows) {
      console.log(rows)
      if (rows) {
        rows.forEach((row) => {
          this.$refs.docTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.docTable.clearSelection()
      }
    },
    //供应商选项框发生变化时的回调函数
    // getcheckList(){
    //   this.addPurchaseForm.goodslist=[]
    //   if (this.addPurchaseForm.s_id == ''){
    //     // this.adddisabled=true
    //     return true
    //   }else{
    //     this.querInfo.s_id=this.addPurchaseForm.s_id
    //     this.getGoodsList(this.querInfo)
    //     // this.adddisabled=false
    //   }
    // },
    add(){
      this.addPurchaseDialogVisible=true
    },

    changeFun(selection) {
      let count = 0;
      this.Goodslist.forEach((items,indexs)=>{
        this.addPurchaseForm.goodslist.forEach((item,index)=>{
          if (items.ga_id === item.ga_id){
            this.addPurchaseForm.goodslist.splice(index, 1)
            count++;
          }
        })
      })
      if(count != this.querInfo.limit){
        this.Goodslist.forEach((item,index)=>{
          this.asd([],item)
        })
      }else{
        count = 0;
      }
    },
    // asd(selection, row){
    //   console.log(selection, row)
    //   let a=true
    //   if(this.addPurchaseForm.goodslist.length==0){
    //     // console.log(this.addPurchaseForm.goodslist)
    //     this.addPurchaseForm.goodslist.push(row);
    //   }else{
    //     this.addPurchaseForm.goodslist.forEach((item,index)=>{
    //       if (item.ga_id !== row.ga_id){
    //         // console.log(1)
    //       }else{
    //         a=false
    //         return
    //       }
    //     })
    //     if (a){
    //       this.addPurchaseForm.goodslist.push(row);
    //     }
    //   }
    // },
    asd(selection, row) {
      let is_exists = true;
      this.addPurchaseForm.goodslist.forEach((item,index)=>{
        if (this.addPurchaseForm.goodslist[index].ga_id===row.ga_id) {
          is_exists = false;
          this.addPurchaseForm.goodslist.splice(index, 1)
        } else {
          // is_exists = false;
        }
      })
      if (is_exists) {
        this.addPurchaseForm.goodslist.push(row)
        is_exists = true;
      }
    },
    // delGoodsList(id){
    //   this.addPurchaseForm.goodslist.forEach((item,index)=>{
    //     if (item.ga_id===id){
    //       this.addPurchaseForm.goodslist.splice(index,1)
    //     }
    //   })
    // },
    //提交form表单
    async addupPurchase(){
      this.$refs.addPurchaseFormRef.validate(async valid => {
        if (!valid) return
        this.addloading=true
        const {data: res} = await this.$http.post('purchasereturn/add', this.addPurchaseForm)
        this.addloading=false
        if (res.code !== 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.$router.go(-1)
      })
    },
    addgo(){
      this.$router.go(-1)
    },
    // getreturnnum(row){
    //   this.addPurchaseForm.goodslist.forEach((item,index)=>{
    //
    //   })
    //
    // },
    getTotal(row) {
      this.addPurchaseForm.pr_total_price=0
      this.addPurchaseForm.goodslist.forEach((item,index)=>{
        if (item.g_id==row.g_id){
          if (row.pri_number>(row.pi_number-row.pri_warhouse_number)){
            item.pri_number=(row.pi_number-row.pri_warhouse_number)
          }
        }
        if (item.pri_total_price==0){
            if (item.pri_number<0){
              item.pri_number=0
            }else if(item.pri_price<0){
              console.log(item.pri_price)
              item.pri_price=0
            }
            item.pri_total_price=item.pri_number*1*item.pri_price*1
          }else{
            if (item.pri_number<0){
              item.pri_number=0
            }else if(item.pri_price<0){
              console.log(item.pri_price)

              item.pri_price=0
            }
            item.pri_total_price=item.pri_number*1*item.pri_price*1
            this.addPurchaseForm.pr_total_price += item.pri_total_price*1
          }
      })
    },

    //跳转采购入库
    addPwtergo(){
      this.getPDli(this.addPurchaseForm.p_id)
      this.addPurchaseDialogVisible=false
    },
    handleInput2(e) {
      // 通过正则过滤小数点后两位
      e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
    },
    // addgoods(){
    //   console.log(this.addPurchaseForm.s_id)
    //   this.$router.push({
    //     path:"/goods/addgoods",
    //     query:{id:this.addPurchaseForm.s_id,typeindex:1}
    //   })
    // },
    //添加供应商
    // AddSupplierSelect() {
    //   this.addSupplierDialogVisible=true
    //   this.getWarehouseArea()
    // },
    //监听添加对话框的显示与隐藏
    addSupplieDialoghandleClose(){
      this.$refs.addsupplierFormRef.resetFields()
      this.selectListarea = []
      this.addsupplierForm={}
    },
    //点击确定提交添加form表单
    // addSupplier(){
    //   this.$refs.addsupplierFormRef.validate(async valid => {
    //     if (!valid) return
    //     const {data: res} = await this.$http.post('supplier/add', this.addsupplierForm)
    //     if (res.code !== 200) return this.$message.error(res.msg)
    //     this.$message.success("添加成功")
    //     this.addSupplierDialogVisible = false
    //     this.getsupplierList()
    //   })
    // },
    //添加 监听级联选择器 选中节点变化时触发
    // addSupplierChanged(){
    //   this.addsupplierForm.s_province=this.addsupplierForm.region[0]
    //   this.addsupplierForm.s_city=this.addsupplierForm.region[1]
    //   this.addsupplierForm.s_area=this.addsupplierForm.region[2]
    // },
    //商品分类
    // parentCateChanged() {
    //   this.querInfo.g_cid=this.selectefKeys[this.selectefKeys.length-1]
    //   console.log(this.querInfo.g_cid)
    //   this.getGoodsList(this.querInfo)
    // },
    // //获取商品分类
    // async getcateList() {
    //   const {data: res} = await this.$http.get('category/getMainList/3')
    //   if (res.code !== 200) {
    //     return this.$message.error(res.msg)
    //   }
    //   // 把数据列表 赋值
    //   this.categorieslList = res.data
    // },


  },
  computed: {
    // totalPrice() {
    //   let result = 0;
    //   for (let i = 0; i < this.addPurchaseForm.goodslist.length; i++) {
    //     this.addPurchaseForm.goodslist[i].pi_total_price =
    //         parseInt(this.addPurchaseForm.goodslist[i].pi_number) * parseInt(this.addPurchaseForm.goodslist[i].pi_price);
    //     result = parseInt(result) + parseInt(this.addPurchaseForm.goodslist[i].pi_total_price);
    //   }
    //
    //   return result
    // },
  },


}
</script>
<style lang="less" scoped>
.p_price,.p_purchase_id,.p_date,.p_remark,.p_other_price{
  width: 300px;
}
.el-select{
  width: 300px;
}
.addsupplierselect{
  margin-left: 30px;
}
/deep/.el-dialog{
  height: 75vh;
  overflow: auto;
}
/deep/::-webkit-scrollbar {
  width: 10px; /*对垂直流动条有效*/
}
/*定义滚动条的轨道颜色、内阴影及圆角*/
/deep/::-webkit-scrollbar-track{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #ffffff;
  border-radius: 3px;
}
/*定义滑块颜色、内阴影及圆角*/
/deep/::-webkit-scrollbar-thumb{
  border-radius: 7px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #E8E8E8;
}

</style>
